<template>
  <div class="lineRow" v-loading="loading">
    <h3 title="点击展开详情" style="cursor: pointer;font-size: 14px" @click="clickbtn" class="title">
     图表 <i
        :class="this.chartShow?'el-icon-caret-top':'el-icon-caret-bottom'"></i></h3>
    <div id="main" ref="bar_dv" v-if="chartShow" style="width: 100%;height:400px;margin-top: 20px;"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'LineChart',
  props: {
    title: {
      type: String
    },
    dataList: {
      type: Array
    },
    lineType: {
      type: String

    }
  },
  data() {
    return {
      chartOption: {},
      chartShow: false,
      loading: false

    }
  },

  methods: {
    async getChartDataRevenue() {
      // let chartDom = document.getElementById('main')
      setTimeout(() => {
        let myChart = echarts.init(this.$refs.bar_dv)
        let emphasisStyle = {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)'
          }
        }
        let xAxisData = []
        if (this.lineType === 'kol') {
          for (let i = 0; i < 12; i++) {
            xAxisData.push(i + 1 + '月(' + this.dataList[i]['coop_duration'] + '个月)')
          }
        } else
        {
          for (let i = 0; i < 12; i++) {
            xAxisData.push(i + 1 + '月')
          }
        }

        let income_total = []
        let cost_total = []
        let profit = []
        this.dataList.forEach((item) => {
          income_total.push(item['income_total'])
          cost_total.push(item['cost_total'])
          profit.push(item['profit'])
        })
        let option = {
          legend: {
            orient: 'vertical',
            right: 10,
            top: 'center'
          },
          brush: {
            toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
            xAxisIndex: 0
          },
          toolbox: {
            feature: {
              magicType: {
                type: ['stack']
              },
              dataView: {}
            }
          },
          title: {
            text: `${this.lineType==='kol'?'红人':'项目组'}收支利润图`,
            left: 'center'
          },
          tooltip: {},
          xAxis: {
            data: xAxisData,
            name: 'X Axis',
            axisLine: { onZero: true },
            splitLine: { show: false },
            splitArea: { show: false }
          },
          yAxis: {},
          grid: {
            bottom: 100
          },
          colorList: ['#EA7267', '#F0D84B', '#7AE72F'],
          series: [
            {
              name: '收入合计',
              type: 'bar',
              stack: 'one',
              emphasis: emphasisStyle,
              data: income_total,
              color:'#ed7d31'
            },
            {
              name: '支出合计',
              type: 'bar',
              stack: 'one',
              emphasis: emphasisStyle,
              data: cost_total,
              color:'#70ad47'
            },
            {
              name: '预计利润',
              type: 'line',
              emphasis: emphasisStyle,
              data: profit,
              color:'#8cb9e2'
            }
          ]
        }
        myChart.on('brushSelected', function(params) {
          var brushed = []
          var brushComponent = params.batch[0]
          for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
            var rawIndices = brushComponent.selected[sIdx].dataIndex
            brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '))
          }
          myChart.setOption({
            title: {
              backgroundColor: '#333',
              text: 'SELECTED DATA INDICES: \n' + brushed.join('\n'),
              bottom: 0,
              right: '10%',
              width: 100,
              textStyle: {
                fontSize: 12,
                color: '#fff'
              }
            }
          })
        })
        myChart.setOption(option)
        this.loading = false
      }, 1000)

    },
    clickbtn() {
      this.chartShow = !this.chartShow
      if (this.chartShow) {
        this.loading = true
        this.getChartDataRevenue()
      }
    }
  },

}
</script>

<style scoped>


</style>
