<template>
  <div class="lineRow" v-loading="loading">
    <h3 title="点击展开详情" style="cursor: pointer;font-size: 14px" @click="clickbtn" class="title">
      图表 <i
        :class="this.chartShow?'el-icon-caret-top':'el-icon-caret-bottom'"></i></h3>
    <div id="main" ref="bar_dv" v-if="chartShow" style="width: 100%;height:400px;margin-top: 20px;"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'LineChart',
  props: {
    title: {
      type: String
    },
    dataList: {
      type: Array
    }
  },
  data() {
    return {
      chartOption: {},
      chartShow: false,
      loading: false

    }
  },

  methods: {
    async getChartDataRevenue() {
      // let chartDom = document.getElementById('main')
      setTimeout(() => {
        let myChart = echarts.init(this.$refs.bar_dv)

        let xAxisData = []


        let income_total = []
        let cost_total = []
        let profit = []
        this.dataList.forEach((item) => {
          income_total.push(item['income_total'])
          cost_total.push(item['cost_total'])
          profit.push(item['profit'])
          xAxisData.push(item.nickname)

        })

        let option = {
          legend: {
            orient: 'vertical',
            right: 10,
            top: 'center'
          },
          brush: {
            toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
            xAxisIndex: 0
          },
          toolbox: {
            feature: {
              magicType: {
                type: ['stack']
              },
              dataView: {}
            }
          },
          title: {
            text: '单个项目组利润分析',
            left: 'center'
          },
          tooltip: {},
          xAxis: {
            data: xAxisData,
            name: 'X Axis',
            axisLine: { onZero: true },
            splitLine: { show: false },
            splitArea: { show: false }
          },
          yAxis: {},
          grid: {
            bottom: 100
          },
          colorList: ['#EA7267', '#F0D84B', '#7AE72F'],
          series: [
            {
              name: '收入合计',
              type: 'bar',
              smooth: false,
              // showSymbol: false,/
              symbolSize: 8,
              zlevel: 3,
              color: '#EA7267',

              // lineStyle: {
              //   normal: {
              //     color: '#EA7267'
              //   }
              // },

              data: income_total
            },
            {
              name: '支出合计',
              type: 'bar',
              smooth: false,
              // showSymbol: false,/
              symbolSize: 8,
              zlevel: 3,
              color: '#70ad47',
              data: cost_total
            },
            {
              name: '利润',
              type: 'bar',
              smooth: false,
              // showSymbol: false,/
              symbolSize: 8,
              zlevel: 3,
              color: '#8cb9e2',
              data: profit
            }
          ]
        }
        myChart.on('brushSelected', function(params) {
          var brushed = []
          var brushComponent = params.batch[0]
          for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
            var rawIndices = brushComponent.selected[sIdx].dataIndex
            brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '))
          }
          myChart.setOption({
            title: {
              backgroundColor: '#333',
              text: 'SELECTED DATA INDICES: \n' + brushed.join('\n'),
              bottom: 0,
              right: '10%',
              width: 100,
              textStyle: {
                fontSize: 12,
                color: '#fff'
              }
            }
          })
        })
        myChart.setOption(option)
        this.loading = false
      }, 1000)

    },
    clickbtn() {
      this.chartShow = !this.chartShow
      if (this.chartShow) {
        this.loading = true
        this.getChartDataRevenue()
      }
    }
  }

}
</script>

<style scoped>


</style>
