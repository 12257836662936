<template>
<div>
  <header class="head">
    <span><b>部门组：</b>{{ dataInfo.group_name || '-' }}</span>
    <span><b>时间： </b>{{ selectYear.slice(0, 4) }}</span>
  </header>
  <el-date-picker
      v-model="selectYear"
      type="year"
      value-format="yyyy-MM-dd"
      @change="handleYear"
      placeholder="选择年">
  </el-date-picker>
  <br>
  <br>
  <el-table :data="colData" ref="multipleTable"
            default-expand-all
            row-key="value"
            :tree-props="{children: 'children'}"
            v-loading="tableLoading" border>
    <el-table-column align="center" label="月份" min-width="120"
                     show-overflow-tooltip prop="name"></el-table-column>
    <el-table-column align="right" :label="Number(item.month.slice(5,7))+'月'" min-width="100"
                     v-for="(item,index) in newDataList" :key="index"
                     show-overflow-tooltip>
      <template slot-scope="{row}">
        {{ moneyFormat(item[row.value]) }}
      </template>
    </el-table-column>
    <el-table-column label='合计' width='180' align="right">
          <span slot-scope='{ row }'>
           {{ moneyFormat(calcRow(row)) }}
          </span>
    </el-table-column>
  </el-table>
  <line-chart lineType="team" :dataList="newDataList"/>

</div>
</template>

<script>
import { mapGetters } from 'vuex'
import LineChart from '@/pages/kolModel/components/LineChart.vue'

export default {
  name: 'TeamList',
  data() {
    return {
      colData: [
        {
          value: 'income_total',
          name: '收入合计',
          width: 100,
          children: [
            {
              value: 'ad_total',
              name: '广告收入',
              width: 100
            },
            {
              value: 'live_commission_total',
              name: '直播佣金',
              width: 100
            },
            {
              value: 'short_video_total',
              name: '短视频收入',
              width: 100
            }
          ]
        },
        {
          value: 'cost_total',
          name: '费用合计',
          width: 100,
          children: [
            {
              value: 'kol_divide_total',
              name: '网红分成',
              width: 100
            },
            {
              value: 'promotion_total',
              name: 'MCN推广',
              width: 100
            },
            {
              value: 'live_put_total',
              name: '直播投放金',
              width: 100
            },
            {
              value: 'expenses_total',
              name: '报销',
              width: 100
            },
            {
              value: 'fixed_total',
              name: '预估固定费用',
              width: 100
            },
            {
              value: 'labor_cost',
              name: '人工成本',
              width: 100
            }
            ,
            {
              value: 'rebate_total',
              name: '返点费用',
              width: 100
            }
          ]
        },
        {
          value: 'profit',
          name: '利润',
          width: 100
        },
        {
          value: 'fans_total',
          name: '总粉丝数',
          width: 100
        },
        {
          value: 'fans_incr_rate',
          name: '新增粉丝数',
          width: 100
        },
        {
          value: 'fans_price',
          name: '粉丝单价',
          width: 100
        },
        {
          value: 'single_fan_income',
          name: '单粉收入',
          width: 100
        }
      ],
      dataList: [],
      tableLoading: true,
      projectDate: '',
      deptId: '',
      activeName: 'all',
      dataYearList: [],
      newDataList: [],
      selectYear: ''
    }
  },
  components: { LineChart },
  props: {
    dataInfo: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    getDate() {
      let myDate = new Date()
      let year = myDate.getFullYear()
      let month = myDate.getMonth() + 1      //获取当前月份(0-11,0代表1月)
      return { year: year, month: month }
    },
    countDate() {
      if (this.getDate.year == Number(this.projectDate.slice(0, 4))) {
        return this.getDate.month
      } else
        return false
    }

  },
  methods: {
    ///其他数据计算
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    calcRow(row) {
      let colValue = row.value
      let cont = 0
      this.newDataList.forEach((item) => {
        if (!window.isNaN(item[colValue])) {
          cont = Number(cont) + Number(item[colValue])
        }
      })
      return cont
    },
    handleYear() {
      console.log(this.selectYear)
      this.selectYear ? this.getDataList(this.selectYear) : this.getDataList()
    },
    getList() {
      //初始化
      // this.isLoading = true
      this.newDataList = []
      this.getDataList()
    },
    async getDataList(month = this.projectDate) {

      this.tableLoading = false
      this.dataList = await this.$api.modelGetYear({ dept_id: this.deptId, month: month })
      this.getNewCol()
    },
    getNewCol: function() {
      //创建12列数据
      let j = 0
      let month = this.dataList.map((item) => {
        return Number(item.month.slice(5, 7) - 1)
      })
      for (let i = 0; i < 12; i++) {
        if (!month.includes(i)) {
          let date = `${this.projectDate.slice(0, 4)}-${String(i < 9 ? '0' + Number(i + 1) : Number(i + 1))}-01`
          let newData = {
            income_total: '-',
            ad_total: '-',
            live_commission_total: '-',
            short_video_total: '-',
            cost_total: '-',
            kol_divide_total: '-',
            live_put_total: '-',
            expenses_total: '-',
            fixed_total: '-',
            fans_price: '-',
            fans_total: '-',
            single_fan_income: '-',
            fans_incr_rate: '-',
            profit: '-',
            month: date,
            promotion_total: '-'
          }
          if (this.countDate) {
            if (!this.countDate - 1 >= i) {
              newData = {
                income_total: 0,
                ad_total: 0,
                live_commission_total: 0,
                short_video_total: 0,
                cost_total: 0,
                kol_divide_total: 0,
                live_put_total: 0,
                expenses_total: 0,
                fixed_total: 0,
                fans_price: 0,
                fans_total: 0,
                single_fan_income: 0,
                fans_incr_rate: 0,
                profit: 0,
                month: date,
                promotion_total: 0
              }
            }
          }
          this.$set(this.newDataList, i, newData)
        } else {
          this.dataList[j].coop_duration = this.signTime != '-' ? this.signTime + i + 1 : this.signTime
          this.$set(this.newDataList, i, JSON.parse(JSON.stringify(this.dataList[j])))
          j++
        }
      }
      this.tableLoading = false
    },

  },
  mounted() {

    this.deptId = this.$route.params.id
    this.projectDate = this.$route.query.month
    this.selectYear = `${this.projectDate.slice(0, 4)}-01-01`
    this.getList()

  }
}
</script>

<style>


.el-table .dark-green {
  font-weight: bold !important;
  font-size: 18px !important;
  background: #5ca58d !important;
  color: black !important;
}

</style>
<style lang="scss" scoped>
.head {
  padding: 20px 0;
  font-size: 16px;

  > span {
    display: inline-block;
    margin-right: 20px;
  }
}
</style>
